<template>
  <div id="myModal1" class="modal">

    <!-- Modal content -->
    <div class="modal-content1">
      <i class="fa-solid fa-envelope" style="color: rgba(51,51,51,0.29)"></i>
      <span style="right: 20px; top: 10px; position: absolute;" class="close" onclick="closeFunction()">&times;</span>
      <a style="color: #333333; text-align: center" href="mailto:fritesenzo@hotmail.com">Fritesenzo@hotmail.com</a>
    </div>

  </div>
  <nav class="bord navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger fonthead" href="#page-top">Frites&zo</a>
      <button
          class="menubutton  navbar-toggler navbar-toggler-right text-uppercase font-weight-bold kleur text-white rounded"
          type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive"
          aria-expanded="false" aria-label="Toggle navigation">
        Menu
        <i class="fas fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 mx-lg-1" style="color: #333333"><a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                                                      href="#portfolio">Foto's</a></li>
          <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                               href="#about" style="display: inline-block">Informatie</a></li>
          <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                               href="#contact" onclick="myFunction()">Contact</a></li>
        </ul>
      </div>
    </div>
  </nav>
  <h3 style="margin-top: 130px; text-align: center;padding: 0 10px;">Voor actuele openingstijden en vakantiedata kijkt u op onze social media, zoals Facebook en Instagram!</h3>
  <!-- Masthead-->
  <header class="masthead bg-primary text-white text-center">
    <div class="container d-flex align-items-center flex-column">

      <!--background-->
      <!-- Masthead Avatar Image-->
      <img class="masthead-avatar x mb-5" src="img/logo.png"/>
      <!-- Masthead Heading-->
      <h1 class="masthead-heading text-uppercase fonthead mb-0">Frites&zo</h1>
      <!-- Icon Divider-->
      <div class="divider-custom divider-light">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
        <div class="divider-custom-line"></div>
      </div>
      <!-- Masthead Subheading-->
      <p class="masthead-subheading font-weight-light x mb-0">Frites & Zo, de gezellige oudhollandse Friteskraam</p>
    </div>
  </header>
  <!-- Portfolio Section-->
  <section class="page-section portfolio" id="portfolio" style="background-color: #ede0d4">
    <div class="container">
      <!-- Portfolio Section Heading-->
      <h2 class="page-section-heading text-center text-uppercase mb-0" style="color: #333333">Foto's</h2>
      <!-- Icon Divider-->
      <div class="divider-custom">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
        <div class="divider-custom-line"></div>
      </div>
      <!-- Portfolio Grid Items-->
      <div class="row portfolio-row container">
        <img class="img-fluid" src="img/new9.jpeg" alt="Frites&zo"/>
        <img class="img-fluid" src="img/patatkraam-fritesenzo.webp" alt="Frites&zo"/>
        <img class="img-fluid" src="img/fritesenzo-kraam.JPG" alt="Frites&zo"/>
        <img class="img-fluid" src="img/patat-fritesenzo.JPG" alt="Frites&zo" />
        <img class="img-fluid" src="img/new1b.jpg" alt="Frites&zo"/>
        <img class="img-fluid" src="img/new10.jpeg" alt="Frites&zo"/>
        <img class="img-fluid" src="img/new5.jpeg" alt="Frites&zo"/>
        <img class="img-fluid" src="img/new12b.jpeg" alt="Frites&zo"/>
        <img class="img-fluid" src="img/new4.jpeg" alt="Frites&zo"/>
        <img class="img-fluid" src="img/staplek.jpg" alt="Frites&zo"/>
      </div>
    </div>
  </section>
  <!-- About Section-->
  <section class="page-section bg-primary text-white mb-0" id="about">
    <div class="container">
      <!-- About Section Heading-->
      <h2 class="page-section-heading text-center text-uppercase text-white">Informatie</h2>
      <!-- Icon Divider-->
      <div class="divider-custom divider-light">
        <div class="divider-custom-line"></div>
        <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
        <div class="divider-custom-line"></div>
      </div>
      <!-- About Section Content-->
      <div class="row">
        <div class=""><p class="lead">Frites & Zo, de gezellige oudhollandse Friteskraam, waar je
          kunt genieten van zo’n ouderwetse puntzak frites met mayonaise. Wij komen graag op uw Feest of
          Evenement. Ook kunnen wij voor u andere hapjes en drankjes verzorgen. Voor uw feest kunt u al een
          ‘all-you-can-eat’ arrangement bestellen vanaf €13,- per persoon. Alle snacks met een sausje. Kinderen
          3 t/m 10 jaar €8,50. </p></div>
        <div class=""><p class="lead">Naast de producten die worden vermeld bij de arrangementen,
          kunnen wij ook salades (b.v huzaar of zalm), soep en diverse broodjes maken, dit in overleg en naar
          uw wens. Te denken valt aan bijv. groentesoep, een heerlijke bruine bol belegd met gerookte zalm,
          roomkaas en sla met dressing of een bol gezond, met achterham/kaas/komkommer/ <br>tomaat/sla en ei. Wilt
          u gebruik maken van onze 3 statafels en 2 picknicksets (tafels en banken, kleedjes), dan is dat
          mogelijk.</p></div>
        <div class=""><p class="lead">Bij een boeking in een plaats, 20 km en verder buiten
          Oosterwolde, rekenen wij een starttarief van €50,- en 21 ct/p/km. De 3 arrangementen vind u
          hieronder. Het minimale boek bedrag is €350. Verdere info, over bijvoorbeeld de voorwaarden, kunnen
          wij u, samen met de arrangementen, even per mail toesturen. Bel of mail ons, mocht u nog vragen
          hebben. <br> Hartelijke groet, Cor & Liesbeth Veldhoen<br>
          <br>
          <span style="text-align: center; font-style: italic;">(prijs- en productwijzigingen onder voorbehoud)</span></p>
        </div>
      </div>
      <!-- About Section Button-->
      <div class="text-center mt-4">
        <a class="btn btn-xl btn-outline-light prijsfix" data-toggle="modal" data-target="#portfolioModal7">
          <div class="portfolio-item mx-auto">
            <i class="fas fa-bars mr-2"></i>
            Arrangementen
          </div>
        </a>
      </div>
    </div>
  </section>
  <!-- Footer-->
  <footer class="footer text-center">
    <div class="container">
      <div class="row footer-row">
        <!-- Footer Location-->
        <div class="col-lg-4 mb-5 mb-lg-0">
          <h4 class="text-uppercase mb-4">Locatie</h4>
          <p class="lead mb-0">
            Oosterwolde GLD
            <br/>
            Mheneweg Noord 22
          </p>
        </div>
        <!-- Footer Social Icons-->
        <div class="col-lg-4 mb-5 mb-lg-0">
          <h4 class="text-uppercase mb-4">Sociale media</h4>
          <a class="btn btn-outline-light btn-social mx-1" target="_blank" href="https://www.facebook.com/fritesenzo/"><i
              class="fab fa-fw fa-facebook-f"></i></a>
          <a class="btn btn-outline-light btn-social mx-1" target="_blank" href="https://www.instagram.com/fritesenzo/?hl=nl"><i
              class="fab fa-fw fa-instagram"></i></a>
        </div>
        <!-- Footer About Text-->
        <div class="col-lg-4">
          <h4 class="text-uppercase mb-4">Bereikbaar via:</h4>
          <p class="lead mb-0">
            Fritesenzo@hotmail.com <br>
            Cor: 0651528120
          </p>
        </div>
      </div>
    </div>
  </footer>
  <!-- Copyright Section-->
  <div class="copyright py-4 text-center text-white">
    <div class="container"><small>Copyright © Frites&zo 2025</small></div>
  </div>

  <div class="scroll-to-top d-lg-none position-fixed">
    <a class="js-scroll-trigger d-block text-center text-white rounded" href="#page-top"><i
        class="fa fa-chevron-up"></i></a>
  </div>
  <div class="portfolio-modal modal fade" id="portfolioModal7" tabindex="-1" role="dialog"
       aria-labelledby="portfolioModal7Label" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="fas fa-times"></i></span>
        </button>
        <div class="modal-body text-center">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <!-- Portfolio Modal - Title-->
                <h2 class="text-secondary text-uppercase mb-0 arr" id="portfolioModal7Label">
                  Arrangementen</h2>
                <!-- Icon Divider-->
                <div class="divider-custom">
                  <div class="divider-custom-line"></div>
                  <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                  <div class="divider-custom-line"></div>
                </div>
                <!-- Portfolio Modal - Image-->
                <p >Arrangementen Frites & Zo - 'all you can eat' - gedurende 2 uur</p>
                <div class="enkeleArr">
                  <h2>Arrangement 1</h2>
                  <p>€13 p.p</p>
                  <br>
                  <p>Patat met sausje</p>
                  <p>Frikandel</p>
                  <p>Kroket</p>
                  <p>Bamischijf</p>
                  <p>Nasischijf</p>
                  <p>Kaassouflé</p>
                  <p>Kipcorn</p>
                  <p>Kipnuggets</p>
                  <p>Pikanto</p>
                  <p>Mexicano</p>
                  <p>Diverse sauzen</p>
                </div>
                <div class="enkeleArr arr2">
                  <h2>Arrangement 2</h2>
                  <p>€15,00 p.p</p>
                  <br>
                  <p>Hetzelfde als arr. 1, met daarbij:</p>
                  <p>Broodje hamburger speciaal</p>
                  <p>Puntzak patat</p>
                  <p>Boerenbrok</p>
                  <p>onze populaire bereklauw</p>
                  <p>Bal gehakt (uit de jus)</p>
                  <p>Shoarmarol</p>
                  <p>Lange lummel</p>
                  <p>Loempia</p>
                  <p>Cheeseburger</p>
                  <p>Broodje kipburger</p>
                  <p>kipcrush</p>
                  <p>Dobbenkroket</p>
                  <p>Gehakt hawaii</p>
                  <p>Diverse sauzen</p>
                </div>
                <div class="enkeleArr arr2">
                  <h2>Arrangement 3</h2>
                  <p>€17,50 p.p</p>
                  <br>
                  <p>Hetzelfde als arr. 1 & 2, met daarbij:</p>
                  <p>Broodje warm vlees</p>
                  <p>Varkensschnitzel</p>
                  <p>Boerenpatat</p>
                  <p>Kipschnitzel</p>
                  <p>Portie Saté van varkenshaas</p>
                  <p>Loempia Extra Speciaal (ham/gebakken</p>
                  <p>ei/satésaus/ananas)</p>
                  <p>Broodje Hamburger Extra Speciaal</p>
                  <p>(champ/gebakken ui/gebakken</p>
                  <p>ei/sausjes)</p>
                  <p>Patat Kip Chili</p>
                  <p>Beefburger</p>
                  <p>Diverse sauzen</p>
                </div>
                <!-- Portfolio Modal - Text-->
                <p class="mb-5">Onze arrangementen.</p>
                <button class="btn btn-primary" data-dismiss="modal">
                  <i class="fas fa-times fa-fw"></i>
                  Sluit venster
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Portfolio Modal 8-->
  <div class="portfolio-modal modal fade" id="portfolioModal8" tabindex="-1" role="dialog"
       aria-labelledby="portfolioModal8Label" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-content">
          <button class="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fas fa-times"></i></span>
          </button>
          <div class="modal-body text-center">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8">
                  <!-- Portfolio Modal - Title-->
                  <h2 class="text-secondary text-uppercase mb-0 arr" id="portfolioModal8Label">
                    Prijslijst</h2>
                  <!-- Icon Divider-->
                  <div class="divider-custom">
                    <div class="divider-custom-line"></div>
                    <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                    <div class="divider-custom-line"></div>
                  </div>
                  <!-- Portfolio Modal - Image-->
                  <img class="img-fluid rounded mb-4" src="img/prijslijst1.jpeg" style="object-fit: contain; height: unset" alt=""/>
                  <img class="img-fluid rounded mb-4" src="img/prijslijst2.jpeg" style="object-fit: contain; height: unset" alt=""/>
                  <!-- Portfolio Modal - Text-->
                  <p class="mb-5">Onze prijslijst.</p>
                  <button class="btn btn-primary" data-dismiss="modal">
                    <i class="fas fa-times fa-fw"></i>
                    Sluit venster
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
